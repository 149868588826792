.contact-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 1em;
}

.contact-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;
  color: #292929;
  cursor: pointer;
}

.link-content {
  color: #2d3748;
}

.link-text {
  position: absolute;
  left: 50%;
  margin-left: 10px;
  padding-top: 5px;
  transition: opacity 0.5s ease-in-out, left 0.5s ease-in-out;
  opacity: 0;
  text-decoration: none;
  color: black;
}

.contact-link:hover .link-text {
  opacity: 1;
  left: 100%;
}

@media screen and (max-width: 600px) {

  .contact-link .fa {
    font-size: 1em;
  }

  .link-text {
    opacity: 0;
    visibility: hidden;
    transition: none;
  }

  .contact-links {
    padding: 0.5em;
  }
}