.library {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  height: 100vh;
  background-color: #f0f0f0;
}

.song-player {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  transition: transform 0.3s ease-in-out;
}

.song-player:hover {
  transform: scale(1.03);
}

.album-cover {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 30%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.album-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease-in-out;
}

.album-cover:hover img {
  transform: scale(1.1);
}

.song-title {
  margin: 0, 0, 10px, 0;
  font-size: 1.2rem;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .song-player {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .song-player {
    width: 300px;
  }

  .player {
    width: 250px;
  }

  .song-title {
    font-size: 16px;
  }

  .album-cover {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .song-player {
    width: 210px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .player {
    width: 180px;
  }

  .album-cover {
    width: 80px;
    height: 80px;
  }

  .library {
    padding-top: 100px;
  }

  .song-title {
    font-size: 14px;
  }
}